import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { Firestore, connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import environment from 'src/environment';
import getFirebaseConfig from '../util/config';

const config = getFirebaseConfig(environment);
// Initialize firebase instance
const firebaseApp: FirebaseApp = initializeApp(config);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = getFirestore(firebaseApp);
const storage: FirebaseStorage = getStorage(firebaseApp);
const defaultBucket: FirebaseStorage = getStorage(firebaseApp, config.storageBucket);

const profilePicsBucket: FirebaseStorage = getStorage(
  firebaseApp,
  config.profilePicsBucket
  // process.env.REACT_APP_PROFILE_PICS_BUCKET
);

if (environment.usingEmulators) {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  // connectStorageEmulator(profilePicsBucket, '127.0.0.1', 9199);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get firebase references for store
const initFirebase = () => ({
  firebaseApp,
  auth,
  firestore,
  storage,
  defaultBucket,
  profilePicsBucket
});

export { auth, defaultBucket, firebaseApp, firestore, initFirebase, profilePicsBucket, storage };
