import { Nilable, Undefinable } from 'tsdef';
import { immerable } from 'immer';
import { DomainSettings } from 'flyid-core/dist/Database/Models/Settings/DomainSettings';
import { UserPublic } from 'flyid-core/dist/Database/Models/User';
import { License } from 'flyid-core/dist/Database/Models/License';
import { APIKey } from 'flyid-core/dist/Database/Models/APIKey';
import { AuthenticationProvider } from 'flyid-core/dist/Database/Models/AuthProvider';

export type SetAuthProviderData = Undefinable<{ [company: string]: AuthenticationProvider }>;
export type SetDomainSettingsData = Nilable<{ [domain: string]: DomainSettings }>;
export type SetUserProfilesData = Nilable<{ [uid: string]: UserPublic }>;
export type SetAuthLicensesData = Nilable<{ [license: string]: License }>;
export type SetAPIKeysData = Nilable<{ [apiKey: string]: APIKey }>;

export class FirestoreState {
  [immerable] = true;

  authProviders: {
    areAuthProvidersLoaded: boolean;
    providers?: { [company: string]: AuthenticationProvider };
  } = { areAuthProvidersLoaded: false };
  domainSettings?: { [domain: string]: DomainSettings };
  userProfiles?: { [uid: string]: UserPublic };
  authLicenses?: { [license: string]: License };
  apiKeys?: { [apiKey: string]: APIKey };
}
